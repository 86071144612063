@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

body {
  font-family: 'Poppins', sans-serif;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: none;
}

.flex-colo {
  @apply flex flex-col justify-center items-center;
}

.flex-rows {
  @apply flex flex-row justify-center items-center;
}

.flex-btn {
  @apply flex justify-between items-center;
}
.transitions {
  @apply transition duration-500 ease-in-out;
}

.box-shadow {
  box-shadow: 0px 0px 13px 0px rgba(24, 50, 83, 0.23);
}
.box-hover {
  box-shadow: 0px 0px 21px -6px rgba(19, 19, 48, 0.24);
}

.h-calc {
  height: calc(100vh - 150px);
}

.h-calc-layout-pages {
  margin: -1px 0px;
}

/* calender */
.rbc-date-cell {
  padding: 10px 30px !important;
  font-size: 12px !important;
  color: #183253;
}
.rbc-time-slot .rbc-label {
  font-size: 12px !important;
  color: #183253;
  padding: 10px 20px;
  text-transform: lowercase !important;
}

/* siku */
.rbc-time-header-content .rbc-header,
.rbc-month-header .rbc-header {
  font-size: 12px !important;
  color: #183253;
  height: 30px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 600 !important;
  background-color: #f8f9fa;
  text-transform: capitalize !important;
}
.rbc-allday-cell {
  display: none !important;
}

.rbc-agenda-table .rbc-header {
  font-size: 12px !important;
  color: #183253;
  font-weight: 600 !important;
  background-color: #f8f9fa;
  height: 50px;
  padding: 0px 10px !important;
  text-transform: capitalize !important;
}
.rbc-time-view {
  background-color: #fff !important;
}
.rbc-events-container {
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.rbc-agenda-event-cell {
  padding: 0px !important;
  margin: 0px !important;
}
.rbc-show-more {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #66b5a3 !important;
}
.rbc-toolbar .rbc-toolbar-label {
  font-size: 20px !important;
  color: #183253;
  font-weight: 500 !important;
  text-transform: capitalize !important;
}
.button-fb {
  box-shadow: 0px 24px 48px 0px rgba(102, 181, 163, 0.65);
}
/* picker */

.react-datepicker {
  font-family: 'Poppins', sans-serif;
  font-size: 14px !important;
  color: #183253;
  text-transform: capitalize !important;
  border: 1px solid #66b5a3 !important;
}

.react-datepicker__triangle {
  color: #66b5a3 !important;
}

.react-datepicker__day,
.react-datepicker__day-name {
  border-radius: 100% !important;
  margin: 5px !important;
  padding: 5px 0 !important;
  width: 37px !important;
  color: #7e7e7e;
}
.react-datepicker__day--selected {
  background-color: #66b5a3 !important;
  color: #fff !important;
}
.react-datepicker__day-name {
  color: #183253 !important;
}

.react-datepicker__day--highlighted-custom-1 {
  background-color: #008000 !important;
  color: #fff !important;
}
.react-datepicker__day--highlighted-custom-2 {
  background-color: #ff0000 !important;
  color: #fff !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #66b5a3 !important;
  color: #fff !important;
}
.react-datepicker__header--time {
  background-color: #f3f5f7 !important;
}
.react-datepicker-time__header {
  font-size: 14px !important;
  color: #183253 !important;
  font-weight: 600 !important;
}
.react-datepicker__day--in-range {
  background-color: #66b5a3;
  color: #fff !important;
}

.react-datepicker-year-header {
  background-color: #f3f5f7 !important;
  font-size: 14px !important;
  color: #183253 !important;
  font-weight: 600 !important;
}

.react-datepicker__month-container {
  border-radius: 5px !important;
  background-color: #ffffff !important;
  width: auto !important;
}

.react-datepicker__month-container .react-datepicker__month-text--today {
  background-color: #a2d2c8 !important;
  color: #fff !important;
}

.react-datepicker__month-container .react-datepicker__month-text--selected {
  background-color: #66b5a3 !important;
  color: #fff !important;
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #d8e2e0;
}

::-webkit-scrollbar-track:hover {
  background-color: #e2edea;
}

::-webkit-scrollbar-track:active {
  background-color: #ccd6d4;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #66b5a3;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #6fc6b2;
}

::-webkit-scrollbar-thumb:active {
  background-color: #43776b;
}

.is-loading {
  .image,
  h2,
  p {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  h4 {
    background: #66b5a3;
    background: linear-gradient(110deg, #b1f0e2 8%, #a9e9da 18%, #b1f0e2 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  .image {
    height: 200px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
